import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowError } from '_molecules';
import { ResultsProps } from '../Results';
import { ConnectedEntities, Graph, SIDEBAR_WIDTH } from './components/Graph';
import { Entity } from './types';
import {
    deserializeGraphV3,
    GraphV3MetadataType,
    GraphV3Raw,
    graphV3ToTrellisGraph,
} from './g3-deserializer';
import { TrellisEdge, TrellisNode } from './payload_types';
import { useParams } from 'react-router-dom';
import {
    Input,
    LoadingSpinner,
    Select,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import classNames from 'classnames';
import { FiLock, FiUnlock } from 'react-icons/fi';
import { capitalize } from '_utils';
import { ImmersivePageLayout } from '../../../components/Layout/ImmersivePageLayout';
import { NetworkGraphSection } from './NetworkGraph';

import * as PIXI from 'pixi.js';
/* Do not touch this, may look stupid but without this the graph will not render because it can't find the PIXI dependency */
PIXI;

const buildTartarusURL = (caseId: string, targetId: string, pathName: string) =>
    [
        'https://tartarus.stage.int.mov/s3/infra1',
        process.env.REACT_APP_STAGE === 'production' ? '' : '/stage',
        '/graph/',
        [caseId, targetId].join('/'),
        pathName,
    ].join('');

export const NetworkGraphSectionV2: FC<
    ResultsProps & {
        onFinishLoading?: () => void;
    }
> = (props) => {
    const { targetData, onFinishLoading = () => null } = props;
    const { t, i18n } = useTranslation();
    const [depth, setDepth] = useState(1);
    const [redFlags, setRedFlags] = useState<'all' | 'hide' | 'show'>('all');
    const [filter, setFilter] = useState('');
    const [companyFilters, setCompanyFilters] = useState<string>('all');
    const [hoverControl, setHoverControl] = useState(false);
    const [hide, setHide] = useState(false);
    const [graphWidth, setGraphWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const [payload, setPayload] =
        useState<
            | {
                  nodes: TrellisNode[];
                  edges: TrellisEdge[];
                  entities: Map<string, Entity>;
              }
            | null
            | {
                  error: string;
              }
        >(null);

    useEffect(() => {
        const fetchGraphData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(
                    buildTartarusURL(caseId, targetId, '/graph.json'),
                );

                const data: GraphV3Raw = await response.json();

                const chunks = data.text
                    .filter((c, i) => data.lowRankChunks.includes(i))
                    .map((c) => `chunk-${c}`);

                const entities = (
                    await Promise.all(
                        chunks.map(async (chunk) => {
                            return (await fetch(
                                buildTartarusURL(
                                    caseId,
                                    targetId,
                                    `/${chunk}.json`,
                                ),
                            ).then((response) => response.json())) as Entity[];
                        }),
                    )
                ).flat();

                const deserializedData = deserializeGraphV3(data);

                const trellisGraph = graphV3ToTrellisGraph(deserializedData);

                setPayload({
                    ...trellisGraph,
                    entities: new Map<string, Entity>(
                        entities.map((e: Entity) => {
                            const coca_entities =
                                deserializedData.flags.get(e.id) ?? [];
                            return [e.id, { ...e, coca_entities }];
                        }),
                    ),
                });

                onFinishLoading();
            } catch (error) {
                console.error(error);
                setPayload({ error: String(error) });
            }
        };

        fetchGraphData();

        return () => {
            setPayload(null);
            setIsLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseId, targetId]);

    const maxDepth = useMemo(() => {
        if (payload === null || 'error' in payload) {
            return 0;
        }

        return Math.max(
            ...Object.values(payload.nodes).map((node) => node.depth),
        );
    }, [payload]);

    const precomputedConnections = useMemo(() => {
        if (payload === null || 'error' in payload) {
            return new Map<string, ConnectedEntities>();
        }

        const result = new Map<string, ConnectedEntities>();

        payload.nodes.forEach((node) => {
            const connected_edges = payload.edges.filter(
                (edge) => edge.source === node.id || edge.target === node.id,
            );

            const connected_nodes = new Set(
                connected_edges.map((edge) =>
                    edge.source === node.id ? edge.target : edge.source,
                ),
            ).add(node.id);

            const connected_edge_ids = new Set(
                connected_edges.map((edge) => edge.id),
            );

            result.set(node.id, {
                connected_nodes,
                connected_edges: connected_edge_ids,
            });
        });

        payload.edges.forEach((edge) => {
            const connected_nodes = new Set([edge.source, edge.target]);
            const connected_edges = new Set([edge.id]);

            result.set(edge.id, {
                connected_nodes,
                connected_edges,
            });
        });

        return result;
    }, [payload]);

    const transformedPayload = useMemo(() => {
        if (payload === null || 'error' in payload) {
            setIsLoading(false);

            return payload;
        }

        const result = applyFilters(payload, {
            maxDepth: depth,
            redFlags,
            filter,
            status: companyFilters,
        });

        setIsLoading(false);

        return result;
    }, [payload, depth, redFlags, filter, companyFilters]);

    const depthOptionValues = useMemo(() => {
        return Array.from({ length: maxDepth }, (_, i) => ({
            id: `${i + 1}`,
            label: `${t('networkGraph.graphLevels')} ${i + 1}`,
            value: `${i + 1}`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxDepth, t, i18n.language]);

    const filterOptionValues = useMemo(
        () => [
            {
                id: 'all',
                label: t('networkGraph.companyStatusAll'),
                value: 'all',
            },
            {
                id: 'unknown',
                label: t('networkGraph.companyStatusUnknown'),
                value: 'unknown',
            },
            ...Array.from(
                new Set(
                    payload === null || 'error' in payload
                        ? []
                        : [
                              ...payload?.nodes
                                  .filter((node) => node.type === 'Company')
                                  .map((node) => {
                                      const nodeStatus = node.meta.get(
                                          GraphV3MetadataType.Status,
                                      );

                                      return nodeStatus
                                          ? String(nodeStatus).toLowerCase()
                                          : 'unknown';
                                  })
                                  .filter((status) => status !== 'unknown'),
                          ],
                ),
            ).map((status) => ({
                id: status.toLowerCase(),
                label: t(`networkGraph.companyStatus${capitalize(status)}`),
                value: status.toLowerCase(),
            })),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, payload, i18n.language],
    );

    const redFlagOptionValues = useMemo(() => {
        return [
            {
                id: 'all',
                label: t('networkGraph.allFlags'),
                value: 'all', // show everything
            },
            {
                id: 'hide',
                label: t('networkGraph.noRedFlags'),
                value: 'hide', // hide red flags
            },
            {
                id: 'show',
                label: t('networkGraph.redFlags'),
                value: 'show', // show only red flags
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, i18n.language]);

    const [isFullscreen, setIsFullscreen] = useState(false);

    if (transformedPayload !== null && 'error' in transformedPayload) {
        return (
            <NetworkGraphSection onFinishLoading={onFinishLoading} {...props} />
        );
    }

    return (
        <ImmersivePageLayout
            title={t('networkGraph.title')}
            fullScreen={isFullscreen}
        >
            <WorkflowError errors={targetData?.workflowErrors} path="network" />
            {transformedPayload === null || isLoading ? (
                <div className="h-full w-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="flex flex-col gap-1 bg-neutral-50 h-full relative">
                    <div
                        className="absolute w-full h-8 z-20"
                        style={{
                            width: graphWidth - SIDEBAR_WIDTH,
                        }}
                        onMouseEnter={() => setHoverControl(true)}
                    />
                    <div
                        onMouseLeave={() => setHoverControl(false)}
                        className={classNames(
                            'flex flex-row gap-2 items-center absolute left-6 top-6 p-2 rounded-md bg-inherit transition-transform flex-wrap hover:translate-y-0 hover:opacity-100 z-40 border-neutral-400/50 border print:hidden',
                            hide && !hoverControl
                                ? 'opacity-0 -translate-y-[200%]'
                                : 'translate-y-0 opacity-100',
                        )}
                        style={{
                            transitionProperty: 'opacity, transform',
                            width: graphWidth - SIDEBAR_WIDTH - 48, // 48px = 2 * 1.5rem = 2 * 6 base units
                        }}
                    >
                        <Input
                            type="text"
                            placeholder={t('networkGraph.searchPlaceholder')}
                            onChange={(e) => setFilter(e.currentTarget.value)}
                        />
                        <Select
                            className="w-32"
                            initialSelection={depthOptionValues.at(0)}
                            options={depthOptionValues}
                            onChange={(e) => setDepth(Number(e))}
                        />
                        <Select
                            className="w-48"
                            initialSelection={filterOptionValues[0]}
                            options={filterOptionValues}
                            onChange={setCompanyFilters}
                        />
                        <Select
                            className="w-72"
                            initialSelection={redFlagOptionValues[0]}
                            options={redFlagOptionValues}
                            onChange={setRedFlags}
                        />
                        <div className="flex flex-row gap-1 items-center justify-center px-2 ml-auto">
                            <TooltipV2>
                                {hide ? (
                                    <>
                                        <TooltipTriggerV2>
                                            <FiUnlock
                                                className="cursor-pointer text-primary-1"
                                                onClick={() => setHide(false)}
                                            />
                                        </TooltipTriggerV2>
                                        <TooltipContentV2>
                                            {t('networkGraph.hideControls')}
                                        </TooltipContentV2>
                                    </>
                                ) : (
                                    <>
                                        <TooltipTriggerV2>
                                            <FiLock
                                                className="cursor-pointer text-primary-1"
                                                onClick={() => setHide(true)}
                                            />
                                        </TooltipTriggerV2>
                                        <TooltipContentV2>
                                            {t('networkGraph.hideControls')}
                                        </TooltipContentV2>
                                    </>
                                )}
                            </TooltipV2>
                        </div>
                    </div>
                    <Graph
                        data={{ ...transformedPayload, precomputedConnections }}
                        onWidthChange={setGraphWidth}
                        onMaximize={() => {
                            setIsFullscreen(true);
                        }}
                        onMinimize={() => {
                            setIsFullscreen(false);
                        }}
                    />
                </div>
            )}
        </ImmersivePageLayout>
    );
};

function applyFilters(
    {
        nodes,
        edges,
        entities,
    }: {
        nodes: TrellisNode[];
        edges: TrellisEdge[];
        entities: Map<string, Entity>;
    },
    {
        maxDepth,
        redFlags,
        filter,
        status,
    }: {
        maxDepth: number;
        redFlags: 'all' | 'hide' | 'show';
        filter: string;
        status: string;
    },
) {
    // Filter nodes based on conditions
    const filteredNodes = nodes.filter((node) => {
        // Check onlyRedFlags condition
        if (redFlags !== 'all') {
            const cocaFlagCount =
                (node.meta.get(GraphV3MetadataType.CoCaFlagCount) as number) ||
                0;

            const wocoFlagCount =
                (node.meta.get(GraphV3MetadataType.WoCoFlagCount) as number) ||
                0;
            const flagCount = cocaFlagCount + wocoFlagCount;

            if (redFlags === 'hide') {
                if (flagCount > 0) {
                    return false;
                }
            } else if (redFlags === 'show') {
                if (flagCount === 0) {
                    return false;
                }
            }
        }

        if (node.type === 'Company' && status !== 'all') {
            const companyStatus = node.meta.get(
                GraphV3MetadataType.Status,
            ) as unknown;
            const parsedStatus =
                typeof companyStatus === 'string' ? companyStatus : 'unknown';
            if (parsedStatus.toLowerCase() !== status.toLowerCase()) {
                return false;
            }
        }

        // Check filter condition
        if (
            filter &&
            !node.label.toLowerCase().includes(filter.toLowerCase())
        ) {
            return false;
        }

        // Check maxDepth condition
        return !(maxDepth !== -1 && node.depth > maxDepth);
    });

    // Filter edges based on filtered nodes
    const filteredEdges = edges.filter(
        (edge) =>
            filteredNodes.some((node) => node.id === edge.source) &&
            filteredNodes.some((node) => node.id === edge.target),
    );

    return { nodes: filteredNodes, edges: filteredEdges, entities };
}
