import React, { FC, Fragment, useState } from 'react';
import { MdOutlineDownload } from 'react-icons/md';
import classnames from 'classnames';
import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Target } from '../../services/dataService';
import { nonProdDataTestId } from '_utils';
import { FiChevronDown } from 'react-icons/fi';
import { BiChevronLeft } from 'react-icons/bi';
import { useMenuContext } from '../../context/Menu';
import { TbAlertCircle } from 'react-icons/tb';

export type NavigationItem = {
    name: string;
    icon?: React.ReactNode;
    href?: string;
    count?: number;
    children?: NavigationItem[];
    open?: boolean;
    disabled?: boolean;
    disabledTooltip?: string;
    dataTestId?: string;
};

export type SubNavigationBarProps = {
    caseData: { title: string; caseId: string };
    title: string;
    navigationItems: NavigationItem[];
    downloadItems: NavigationItem[];
    targetData?: Target | null;
};

const hasHref = (item: NavigationItem) =>
    item.href && !['', '#'].includes(item.href);

interface InactiveLinkProps {
    name: string;
    tooltip?: string;
    icon?: React.ReactNode;
    showExclamationIcon?: boolean;
}

const InactiveLink: FC<InactiveLinkProps> = ({
    name,
    tooltip,
    icon,
    showExclamationIcon,
}: InactiveLinkProps) => (
    <TooltipV2 openDelay={150} placement="right" disabled={!tooltip}>
        <TooltipContentV2 className="max-w-72 z-20">{tooltip}</TooltipContentV2>
        <TooltipTriggerV2 asChild={true}>
            <span className="flex-1 font-jost group flex items-center opacity-50 cursor-auto justify-between pr-4">
                <span className="flex items-center justify-start flex-shrink-0">
                    {name} {!showExclamationIcon ? icon : null}
                </span>
                {showExclamationIcon && <TbAlertCircle />}
            </span>
        </TooltipTriggerV2>
    </TooltipV2>
);

const subNavItemClassName =
    'flex relative px-3 py-1.5 font-jost after:absolute after:top-0 after:left-0 after:w-0.5 after:h-full after:bg-font-light after:bg-opacity-50';

export const SubNavigationBar: FC<SubNavigationBarProps> = ({
    navigationItems,
    downloadItems,
}) => {
    const location = useLocation();
    const { t } = useTranslation();

    const isLocationAmongChildren = (children: Array<any>): boolean =>
        children.some((child) => child.href === location.pathname);

    const [drawersState, setDrawersState] = useState<Record<string, boolean>>(
        navigationItems
            .filter((item): item is NavigationItem => !!item.children)
            .reduce(
                (acc, item) => ({
                    ...acc,
                    [item.name]:
                        isLocationAmongChildren(item.children || []) ||
                        location.pathname === item.href,
                }),
                {},
            ),
    );

    const { collapsed, toggleCollapsed } = useMenuContext();

    return (
        <>
            <div
                className={classnames(
                    'peer fixed h-[calc(100vh-5rem)] text-font-light bg-primary-5 transition-all w-50 origin-left print:hidden',
                    collapsed ? 'scale-x-0' : 'scale-x-100',
                )}
            >
                <div className="flex flex-col flex-grow h-full relative after:content after:block after:absolute after:bottom-full after:left-0 after:w-20 after:h-20 after:bg-primary-5">
                    <div className="flex flex-col flex-grow h-full overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-primary-3 scrollbar-track-primary-5">
                        <nav
                            className="pb-6 pt-7 space-y-6 flex-2 text-lg"
                            aria-label="Sidebar"
                            data-testid={nonProdDataTestId('analysis menu')}
                        >
                            {navigationItems.map((item, index) =>
                                item.children ? (
                                    <Fragment key={index}>
                                        <div>
                                            {item.href ? (
                                                <Link
                                                    to={item.href || ''}
                                                    className={classnames(
                                                        location.pathname ===
                                                            item.href
                                                            ? 'font-bold'
                                                            : 'hover:font-bold',
                                                        'group flex items-center justify-between transition-all pr-4 font-jost',
                                                    )}
                                                >
                                                    <span>
                                                        {item.name}
                                                        {!!item.icon &&
                                                            item.icon}
                                                    </span>

                                                    <span
                                                        onClick={(event) => {
                                                            event.preventDefault();

                                                            setDrawersState(
                                                                (ds) => ({
                                                                    ...ds,
                                                                    [item.name]:
                                                                        !ds[
                                                                            item
                                                                                .name
                                                                        ],
                                                                }),
                                                            );
                                                        }}
                                                    >
                                                        <FiChevronDown
                                                            className={classnames(
                                                                'transition-transform',
                                                                drawersState[
                                                                    item.name
                                                                ]
                                                                    ? 'rotate-180'
                                                                    : 'rotate-0',
                                                            )}
                                                        />
                                                    </span>
                                                </Link>
                                            ) : item.disabled ? (
                                                <InactiveLink
                                                    name={t(item.name)}
                                                    icon={item.icon}
                                                    tooltip={
                                                        item.disabledTooltip
                                                    }
                                                    showExclamationIcon={
                                                        !item.icon
                                                    }
                                                />
                                            ) : (
                                                <div
                                                    className="group flex items-center justify-between transition-all hover:font-bold cursor-pointer pr-4"
                                                    onClick={() =>
                                                        setDrawersState(
                                                            (ds) => ({
                                                                ...ds,
                                                                [item.name]:
                                                                    !ds[
                                                                        item
                                                                            .name
                                                                    ],
                                                            }),
                                                        )
                                                    }
                                                    data-testid={nonProdDataTestId(
                                                        item.dataTestId,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            'font-jost',
                                                            isLocationAmongChildren(
                                                                item.children,
                                                            ) && 'font-bold',
                                                        )}
                                                    >
                                                        {item.name}
                                                    </span>
                                                    <span>
                                                        {
                                                            <FiChevronDown
                                                                className={classnames(
                                                                    'transition-all',
                                                                    drawersState[
                                                                        item
                                                                            .name
                                                                    ]
                                                                        ? 'rotate-180'
                                                                        : 'rotate-0',
                                                                )}
                                                            />
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            <ul
                                                className={classnames(
                                                    'pt-2',
                                                    item.disabled && 'hidden',
                                                    drawersState[item.name]
                                                        ? ''
                                                        : 'hidden',
                                                )}
                                            >
                                                {item.children.map(
                                                    (subItem, index) => (
                                                        <li key={index}>
                                                            {hasHref(subItem) &&
                                                            !subItem.disabled ? (
                                                                <Link
                                                                    to={
                                                                        subItem.href ||
                                                                        ''
                                                                    }
                                                                    className={classnames(
                                                                        location.pathname ===
                                                                            subItem.href
                                                                            ? 'font-bold border-r-6 border-font-light cursor-auto'
                                                                            : 'hover:font-bold',
                                                                        subNavItemClassName,
                                                                        'group items-center justify-start transition-all',
                                                                    )}
                                                                    data-testid={nonProdDataTestId(
                                                                        subItem.dataTestId,
                                                                    )}
                                                                >
                                                                    {subItem.count ? (
                                                                        <>
                                                                            <span className="flex-1">
                                                                                {
                                                                                    subItem.name
                                                                                }
                                                                                {!!subItem.icon &&
                                                                                    subItem.icon}
                                                                            </span>
                                                                            <span className="bg-yellow-500 text-yellow-800 ml-3 inline-block py-0.5 px-3 text-sm font-medium rounded-full">
                                                                                {
                                                                                    subItem.count
                                                                                }
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                subItem.name
                                                                            }
                                                                            {!!subItem.icon &&
                                                                                subItem.icon}
                                                                        </>
                                                                    )}
                                                                </Link>
                                                            ) : (
                                                                <span
                                                                    className={classnames(
                                                                        subNavItemClassName,
                                                                        'cursor-auto',
                                                                    )}
                                                                >
                                                                    <InactiveLink
                                                                        name={
                                                                            subItem.name
                                                                        }
                                                                        icon={
                                                                            subItem.icon
                                                                        }
                                                                        tooltip={
                                                                            subItem.disabledTooltip
                                                                        }
                                                                        showExclamationIcon={
                                                                            !subItem.icon
                                                                        }
                                                                    />
                                                                </span>
                                                            )}
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </div>
                                    </Fragment>
                                ) : hasHref(item) && !item.disabled ? (
                                    <Link
                                        key={item.name}
                                        to={item.href || ''}
                                        className={classnames(
                                            location.pathname === item.href
                                                ? 'font-bold border-r-6 border-font-light cursor-auto'
                                                : 'hover:font-bold',
                                            'group flex items-center justify-start transition-all font-jost',
                                        )}
                                        data-testid={nonProdDataTestId(
                                            item.dataTestId,
                                        )}
                                    >
                                        {item.count ? (
                                            <>
                                                <span className="flex-1">
                                                    {t(item.name)}
                                                    {!!item.icon && item.icon}
                                                </span>
                                                <span className="bg-error-2 text-error-1 ml-3 inline-block py-0.5 px-3 text-sm font-medium rounded-full">
                                                    {item.count}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                {t(item.name)}
                                                {!!item.icon && item.icon}
                                            </>
                                        )}
                                    </Link>
                                ) : (
                                    <InactiveLink
                                        name={t(item.name)}
                                        icon={item.icon}
                                        tooltip={item.disabledTooltip}
                                        showExclamationIcon={!item.icon}
                                        data-testid={nonProdDataTestId(
                                            item.dataTestId,
                                        )}
                                    />
                                ),
                            )}
                        </nav>
                        {downloadItems?.some((item) => item.href !== '#') ? (
                            <div className="px-5 mt-8">
                                <hr className="border-primary-3" />
                                <Paragraph
                                    size="label"
                                    color="blue"
                                    weight="semibold"
                                    className="py-3"
                                >
                                    {t('downloads')}
                                </Paragraph>
                                <div className="flex flex-col gap-3">
                                    {downloadItems.map((downloadItem) => (
                                        <a
                                            key={downloadItem.name}
                                            href={downloadItem.href}
                                            className="text-sm font-normal"
                                            onClick={(event) =>
                                                !hasHref(downloadItem) &&
                                                event.preventDefault()
                                            }
                                        >
                                            <span className="py-2 text-primary-4 mr-3">
                                                <MdOutlineDownload />
                                            </span>
                                            <span
                                                className={classnames(
                                                    !hasHref(downloadItem)
                                                        ? 'text-neutral-400'
                                                        : '',
                                                )}
                                            >
                                                {downloadItem.name}
                                            </span>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                className={classnames(
                    'transition-all text-primary-4 bg-font-light hover:text-font-light hover:bg-primary-4 hover:border-primary-4 border border-primary-5 absolute top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full z-1 cursor-pointer peer-hover:visible hover:visible print:hidden',
                    collapsed
                        ? 'rotate-180 left-25'
                        : 'rotate-0 left-75 invisible',
                )}
                onClick={toggleCollapsed}
            >
                <BiChevronLeft />
            </div>
        </>
    );
};
