import React, { FC, useMemo } from 'react';
import { Badge, Headline, LoadingSpinner, Paragraph } from '_atoms';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HourglassIcon } from '_assets/icons/overview-report-status/hourglass.svg';
import { ReactComponent as CheckMarkIcon } from '_assets/icons/overview-report-status/checkmark.svg';
import { ReactComponent as WarningIcon } from '_assets/icons/overview-report-status/warning-exclamation.svg';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { ReactComponent as CircleIcon } from '_assets/icons/circle.svg';
import { SemiCircleProgressBar } from '../../../components/_atoms/ProgressBar/SemiCircleProgressBar';
import {
    ReportStep,
    StepStatus,
    TargetStatus,
} from '../../../services/dataService';
import classnames from 'classnames';

const ReportStatusIcon: FC<ReportStatusDisplayProps & { classname: string }> = (
    props,
) => {
    if (props.status === 'inProgress') {
        return (
            <HourglassIcon
                className={classnames('animate-spin-and-stay', props.classname)}
            />
        );
    }

    if (props.hasSuspicions) {
        return (
            <WarningIcon
                className={classnames('animate-wiggle', props.classname)}
            />
        );
    }

    return (
        <CheckMarkIcon
            className={classnames('animate-zoom-in-out', props.classname)}
        />
    );
};

const translatedStatus = (
    status: ReportStatus,
    hasSuspicions: boolean,
    reportGenerationOnly: boolean,
): string => {
    if (status === 'inProgress') {
        if (reportGenerationOnly) {
            return 'reportStatusOverview.inProgressGenerationOnly';
        }

        return 'reportStatusOverview.inProgress';
    }

    if (status === 'completedWithIrregularities') {
        if (!hasSuspicions) {
            return 'reportStatusOverview.withIrregularitiesWithoutSuspicions';
        }

        return 'reportStatusOverview.withIrregularitiesWithSuspicions';
    }

    if (!hasSuspicions) {
        return 'reportStatusOverview.completedWithoutSuspicions';
    }

    return 'reportStatusOverview.completedWithSuspicions';
};

// TODO: To be redefined when IOAT-2626 is done
const reportSteps: ReportStep[] = [
    'profile',
    'google_profile',
    'press',
    'network',
    'socialmedia',
    'entire_workflow',
];

// TODO: To be redefined when IOAT-2626 is done
enum PossibleStepStatus {
    Idle = 'idle',
    Started = 'started',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

// TODO: Probably will be removed when IOAT-2626 is done
const translateStepStatus = (status: TargetStatus): PossibleStepStatus => {
    switch (status) {
        case TargetStatus.Created:
        case TargetStatus.FetchingCandidates:
        case TargetStatus.CandidateSelectionPending:
        case TargetStatus.CandidateSelectionCompleted:
        case TargetStatus.FetchingDetails:
        case TargetStatus.HasInitialProfile:
            return PossibleStepStatus.Started;
        case TargetStatus.Completed:
            return PossibleStepStatus.Succeeded;
        case TargetStatus.CompletedWithIrregularities:
        case TargetStatus.Error:
            return PossibleStepStatus.Failed;
        default:
            return PossibleStepStatus.Idle;
    }
};

const getStepCompletionStatus = (status: ReportStatus): string => {
    if (status === 'inProgress') {
        return 'reportStatusOverview.stepsComplete';
    }

    return 'reportStatusOverview.stepsFailed';
};

const getBorderColor = (status: ReportStatus, hasSuspicions: boolean) => {
    if (status === 'inProgress') {
        return 'border-primary-4';
    }

    if (hasSuspicions) {
        return 'border-warning-1';
    }

    return 'border-success-1';
};

const getBackgroundColor = (
    status: ReportStatus,
    hasSuspicions: boolean,
): string => {
    if (status === 'inProgress') {
        return 'bg-primary-4';
    }

    if (hasSuspicions) {
        return 'bg-warning-1';
    }

    return 'bg-success-1';
};

const iconSize = 'h-4 w-4';

type ReportStatus = 'inProgress' | 'completed' | 'completedWithIrregularities';

interface ReportStatusDisplayProps {
    status: ReportStatus;
    hasSuspicions: boolean;
    steps: StepStatus[];
}

export const ReportStatusDisplay: FC<ReportStatusDisplayProps> = (
    props: ReportStatusDisplayProps,
) => {
    const { steps, hasSuspicions, status } = props;
    const { t } = useTranslation();

    // TODO: Remove this when IOAT-2626 is done
    const areStepsDisabled = process.env.REACT_APP_STAGE === 'production';

    const stepStatuses = useMemo(() => {
        if (areStepsDisabled) {
            return [];
        }

        if (steps.length) {
            return steps.map(({ step, status }) => ({
                step,
                status: translateStepStatus(status),
            }));
        }

        return reportSteps.map((step) => ({
            step,
            status: PossibleStepStatus.Idle,
        }));
    }, [areStepsDisabled, steps]);

    const failedStepsCount = stepStatuses.filter(
        ({ status }) => status === PossibleStepStatus.Failed,
    ).length;

    const completedStepsCount = stepStatuses.filter(
        ({ status }) => status !== PossibleStepStatus.Started,
    ).length;

    const progressValue = (completedStepsCount * 100) / stepStatuses.length;

    const reportGenerationOnly = useMemo(() => {
        if (status === 'inProgress') {
            const inProgressSteps = stepStatuses.filter(
                ({ status }) => status === PossibleStepStatus.Started,
            );
            return (
                inProgressSteps.length === 1 &&
                inProgressSteps[0].step === 'entire_workflow'
            );
        }
        return false;
    }, [status, stepStatuses]);

    const translationStatusKey = translatedStatus(
        status,
        hasSuspicions,
        reportGenerationOnly,
    );

    const borderColor = getBorderColor(status, hasSuspicions);
    const backgroundColor = getBackgroundColor(status, hasSuspicions);

    return (
        <div
            className={classnames(
                'relative flex flex-col gap-2 items-center text-center border rounded-md p-6 pt-12 *:max-w-flow-text-lg',
                borderColor,
                backgroundColor,
                'bg-opacity-10',
            )}
        >
            {status === 'inProgress' && (
                <div
                    className={classnames(
                        'absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full border-b',
                        borderColor,
                    )}
                >
                    <SemiCircleProgressBar progress={progressValue} />
                </div>
            )}
            <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <ReportStatusIcon {...props} classname="h-13 w-13" />
            </div>
            {!areStepsDisabled &&
                (status === 'inProgress' || failedStepsCount > 0) && (
                    <Badge
                        background={
                            status === 'inProgress' ? 'blue' : 'darkGray'
                        }
                        className="flex gap-2"
                    >
                        {failedStepsCount > 0 && (
                            <div className="text-white">
                                <ExclamationIcon className={iconSize} />
                            </div>
                        )}
                        <Paragraph color="white" weight="bold">
                            {`${
                                status === 'inProgress'
                                    ? completedStepsCount
                                    : failedStepsCount
                            } / ${stepStatuses.length} ${t(
                                getStepCompletionStatus(status),
                            )}`}
                        </Paragraph>
                    </Badge>
                )}
            <Headline Level="h4" color="dark">
                {t(`${translationStatusKey}.header`)}
            </Headline>
            <Paragraph color="light">
                {t(`${translationStatusKey}.subHeader`)}
            </Paragraph>
            {!areStepsDisabled && (
                <div className="grid grid-cols-3 gap-x-3 gap-y-2 border-t border-neutral-400 mt-5 pt-5 w-full">
                    {stepStatuses.map(({ step, status }) => (
                        <div
                            key={step}
                            className="flex items-center gap-2 text-neutral-500"
                        >
                            <Paragraph color="light" size="small">
                                {t(`reportStatusOverview.steps.${step}`)}
                            </Paragraph>
                            <span>
                                {status === PossibleStepStatus.Started && (
                                    <LoadingSpinner
                                        height={18}
                                        width={18}
                                        className="pl-0 py-0"
                                    />
                                )}
                                {status === PossibleStepStatus.Succeeded && (
                                    <CheckIcon className={iconSize} />
                                )}
                                {status === PossibleStepStatus.Failed && (
                                    <ExclamationIcon className={iconSize} />
                                )}
                                {status === PossibleStepStatus.Idle && (
                                    <CircleIcon className={iconSize} />
                                )}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
